import { Box, IconButton, Modal } from '@mui/material';
import React, { Component } from 'react';
import './App.css';
import PasienBaru from './components/pasienBaru';
import PasienLama from './components/pasienLama';
import { Close } from '@mui/icons-material';
import imgPasienLama from '../src/assets/pasienLama.jpg';
import imgPasienBaru from '../src/assets/pasienBaru.png';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

class App extends Component {
  state = {
    baru: false,
    lama: false
  }

  _handleClick = (val) => (e) => {
    e.preventDefault();
    if (val === 'pasienBaru') {
      this.setState({ baru: true, lama: false });
    } else {
      this.setState({ baru: false, lama: true });
    }
  }

  _handleClose = () => {
    this.setState({ baru: false, lama: false });
  }

  render() {
    const { baru, lama } = this.state;
    return (
      <div className='containerMainMenu'>
        <Modal open={baru}>
          <Box sx={styleModal}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: '#f2f2f2',
                borderRadius: '50%'
              }}>
                <IconButton onClick={this._handleClose}>
                  <Close />
                </IconButton>
              </div>
              <PasienBaru />
            </div>
          </Box>
        </Modal>
        <Modal open={lama}>
          <Box sx={styleModal}>
            <div>
              <div style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: '#f2f2f2',
                borderRadius: '50%'
              }}>
                <IconButton onClick={this._handleClose}>
                  <Close />
                </IconButton>
              </div>
              <PasienLama />
            </div>
          </Box>
        </Modal>
        <div className='animatedText'>Selamat datang di pendaftaran pasien rawat jalan</div>
        <div className='wrappMainMenu'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div class="flip-card-left" onClick={this._handleClick('pasienBaru')}>
              <div class="flip-card-inner-left">
                <div class="flip-card-front-left">
                  <h3>Pasien Baru</h3>
                  <img
                    alt='' src={imgPasienBaru} height={300}
                    style={{ marginLeft: '-20px' }}
                  />
                </div>
                <div class="flip-card-back-left">
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'justify',
                      padding: '20px'
                    }}>
                    Silahkan Akses Menu ini, Untuk Calon Pasien Yang <b>Belum Pernah</b>&nbsp;
                    Berobat Sebelumnya. <b>Belum</b> Memiliki KIP (Kartu Identitas Pasien)&nbsp;
                    dan atau <b>Belum</b> Memiliki Nomor Medical Record.
                  </p>
                </div>
              </div>
            </div>
            <div class="flip-card-right" onClick={this._handleClick('pasienLama')}>
              <div class="flip-card-inner-right">
                <div class="flip-card-front-right">
                  <h3>Pasien Lama</h3>
                  <img
                    alt='' src={imgPasienLama} height={230}
                    style={{ marginLeft: '-20px', marginTop: '50px' }}
                  />
                </div>
                <div class="flip-card-back-right">
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'justify',
                      padding: '20px'
                    }}>
                    Silahkan Akses Menu Ini, Untuk Pasien Yang <b>Sudah Pernah</b> Berobat Sebelumnya,&nbsp;
                    <b>Sudah</b> Memiliki KIP (Kartu Identitas Pasien) dan atau <b>Sudah</b>&nbsp;
                    Memiliki Nomor Medical Record.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;