import { Box, Button, Grid, IconButton, LinearProgress, MenuItem, Modal, TextField } from '@mui/material';
import React, { Component } from 'react';
import './styles.css';
import StartFirebase from '../../configFirebase/index';
import { ref, set, onValue } from 'firebase/database';
import { Cancel, CheckCircle, Close, HelpOutline } from '@mui/icons-material';
import { Document, PDFViewer, Page, View } from '@react-pdf/renderer';
import PreviewDoc from '../previewDoc';

const db = StartFirebase();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styleModalConfirm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styleModalListPatients = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '555px',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  marginTop: '30px',
  typography: 'body1'
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
}

const section = {
  margin: 10,
  padding: 10,
}

const viewerListPatients = {
  width: '99%',
  height: '550px',
}

class PasienBaru extends Component {
  state = {
    nik: '',
    namaLengkap: '',
    jenisKelamin: '',
    tempatLahir: '',
    tglLahir: '',
    alamat: '',
    noTelp: '',
    asuransi: '',
    noAsuransi: '',
    noRujukan: '',
    poli: '',
    tglBerkunjung: '',
    jamBerkunjung: '',
    noRegistrasi: '',
    noRM: '',
    database: [],
    dataTable: [],
    modalSubmit: false,
    loading: false,
    previewDoc: false,
    confirmation: false
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  getAllData = () => {
    let newData = [...this.state.dataTable.reduce((a, c) => (a.set(c.noRM, c)), new Map()).values()];
    return {
      nik: this.state.nik,
      namaLengkap: this.state.namaLengkap,
      jenisKelamin: this.state.jenisKelamin,
      tempatLahir: this.state.tempatLahir,
      tglLahir: this.state.tglLahir,
      alamat: this.state.alamat,
      noTelp: this.state.noTelp,
      asuransi: this.state.asuransi,
      noAsuransi: this.state.noAsuransi,
      noRujukan: this.state.noRujukan,
      poli: this.state.poli,
      tglBerkunjung: this.state.tglBerkunjung,
      jamBerkunjung: this.state.jamBerkunjung,
      noRM: newData.length + 1,
      queueNumber: this.state.dataTable.length + 1
    };
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleRegist = (e) => {
    e.preventDefault();
    let id = Math.random().toString(36).substring(2, 12);
    let db = this.state.database;
    let data = this.getAllData();
    set(ref(db, 'pasien/' + data.queueNumber), {
      noRM: data.noRM?.toString(),
      nik: data?.nik,
      namaLengkap: data?.namaLengkap,
      jenisKelamin: data?.jenisKelamin,
      tempatLahir: data?.tempatLahir,
      tglLahir: data?.tglLahir,
      alamat: data?.alamat,
      noTelp: data?.noTelp,
      asuransi: data?.asuransi,
      noAsuransi: data?.noAsuransi,
      noRujukan: data?.noRujukan,
      poli: data?.poli,
      tglBerkunjung: data?.tglBerkunjung,
      jamBerkunjung: data?.jamBerkunjung,
      noRegistrasi: id,
      queueNumber: data?.queueNumber
    });

    this.setState({
      modalSubmit: true, loading: true,
      noRegistrasi: id, noRM: data.noRM?.toString()
    }, () => setTimeout(() => this.setState({ loading: false }), 500));
  }

  _handleShowDoc = () => {
    this.setState({ previewDoc: !this.state.previewDoc })
  }

  _handleCloseAll = () => {
    this.setState({
      modalSubmit: false,
      confirmation: false,
      nik: '',
      namaLengkap: '',
      jenisKelamin: '',
      tempatLahir: '',
      tglLahir: '',
      alamat: '',
      noTelp: '',
      noRM: '',
      noRegistrasi: '',
      asuransi: '',
      noAsuransi: '',
      noRujukan: '',
      poli: '',
      tglBerkunjung: '',
      jamBerkunjung: '',
    }, () => window.location.reload());
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      confirmation: !this.state.confirmation,
      modalSubmit: !this.state.modalSubmit
    });
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
                <IconButton onClick={this._handleCloseModal}>
                  <Close />
                </IconButton>
              </div>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Pendaftaran Berhasil Disimpan!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleShowDoc}
              >
                Lihat Bukti Pendaftaran
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  _renderPreviewDoc = () => {
    return (
      <Modal open={this.state.previewDoc}>
        <Box sx={styleModalListPatients}>
          <IconButton
            style={{
              position: 'absolute',
              top: '-31px',
              right: '-34px',
              zIndex: 2,
              color: 'white'
            }}
            onClick={this._handleShowDoc}
          >
            <Cancel fontSize='large' />
          </IconButton>
          <PDFViewer style={viewerListPatients}>
            <Document>
              <Page size="A4" style={page}>
                <View style={section}>
                  <PreviewDoc data={this.state} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    );
  }

  _renderConfirmation = () => {
    return (
      <Modal open={this.state.confirmation}>
        <Box sx={styleModalConfirm}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <HelpOutline color='success' sx={{ fontSize: 150 }} />
            <h3>Apakah Anda Sudah Menyimpan Bukti Pendaftaran?</h3>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ marginRight: 5 }}
                onClick={this._handleCloseModal}
              >
                Belum
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleCloseAll}
              >
                Sudah
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <h3>Pendaftaran Pasien Baru</h3>
        <Grid className='containerForm' container spacing={2}>
          {this._renderModalSubmit()}
          {this._renderPreviewDoc()}
          {this._renderConfirmation()}
          <Grid item xs={6}>
            <TextField
              fullWidth
              className='wrappField'
              label="NIK"
              name='nik'
              onChange={this._handleChange}
            />
            <TextField
              fullWidth
              className='wrappField'
              label="Nama Lengkap"
              name='namaLengkap'
              onChange={this._handleChange}
            />
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Jenis Kelamin"
              fullWidth
              name='jenisKelamin'
              onChange={this._handleChange}
            >
              {['Laki-Laki', 'Perempuan'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              className='wrappField'
              label="Tempat Lahir"
              name='tempatLahir'
              onChange={this._handleChange}
            />
            <TextField
              fullWidth
              className='wrappField'
              label="Tanggal Lahir"
              type='date'
              name='tglLahir'
              InputLabelProps={{ shrink: true }}
              onChange={this._handleChange}
            />
            <TextField
              fullWidth
              className='wrappField'
              label="Alamat"
              multiline
              rows={4.3}
              type='area'
              name='alamat'
              onChange={this._handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              className='wrappField'
              label="No Telepon"
              name='noTelp'
              onChange={this._handleChange}
            />
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Jenis Asuransi"
              fullWidth
              name='asuransi'
              value={this.state.asuransi}
              onChange={this._handleChange}
            >
              {['Umum', 'BPJS', 'Inhealt'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={this.state.asuransi === 'Umum' ? true : false}
              fullWidth
              className='wrappField'
              label="No Asuransi"
              name='noAsuransi'
              onChange={this._handleChange}
            />
            <TextField
              fullWidth
              className='wrappField'
              label="No Rujukan"
              name='noRujukan'
              onChange={this._handleChange}
            />
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Tujuan Poliklinik"
              fullWidth
              name='poli'
              onChange={this._handleChange}
            >
              {['Obgyn', 'Bedah', 'Penyakit Dalam', 'Jantung', 'Urologi'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              className='wrappField'
              label="Tanggal Berkunjung"
              type='date'
              InputLabelProps={{ shrink: true }}
              name='tglBerkunjung'
              onChange={this._handleChange}
            />
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Jam Berkunjung"
              fullWidth
              name='jamBerkunjung'
              onChange={this._handleChange}
            >
              {['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Button
            disabled={
              this.state.nik === '' ||
                this.state.namaLengkap === '' ||
                this.state.jenisKelamin === '' ||
                this.state.tempatLahir === '' ||
                this.state.tglLahir === '' ||
                this.state.alamat === '' ||
                this.state.noTelp === '' ||
                this.state.asuransi === '' ||
                this.state.noRujukan === '' ||
                this.state.poli === '' ||
                this.state.tglBerkunjung === '' ||
                this.state.jamBerkunjung === '' ? true : false
            }
            fullWidth
            variant='contained'
            onClick={this._handleRegist}
          >
            Daftar
          </Button>
        </Grid>
      </div>
    );
  }
}

export default PasienBaru;