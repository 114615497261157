import { Autocomplete, Box, Button, Grid, IconButton, LinearProgress, MenuItem, Modal, TextField } from '@mui/material';
import React, { Component } from 'react';
import { ref, set, onValue } from 'firebase/database';
import StartFirebase from '../../configFirebase/index';
import moment from 'moment';
import { Cancel, CheckCircle, Close, HelpOutline } from '@mui/icons-material';
import { Document, PDFViewer, Page, View } from '@react-pdf/renderer';
import PreviewDoc from '../previewDoc';

const db = StartFirebase();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styleModalListPatients = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '555px',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  marginTop: '30px',
  typography: 'body1'
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
}

const section = {
  margin: 10,
  padding: 10,
}

const viewerListPatients = {
  width: '99%',
  height: '550px',
}

const styleModalConfirm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class PasienLama extends Component {
  state = {
    database: [],
    dataTable: [],
    dataPasien: {},
    showFields: false,
    noRujukan: '',
    poli: '',
    tglBerkunjung: '',
    jamBerkunjung: '',
    noRegistrasi: '',
    noRM: '',
    modalSubmit: false,
    loading: false,
    confirmation: false,
    previewDoc: false,
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  getAllData = () => {
    return {
      nik: this.state.dataPasien.nik,
      namaLengkap: this.state.dataPasien.namaLengkap,
      jenisKelamin: this.state.dataPasien.jenisKelamin,
      tempatLahir: this.state.dataPasien.tempatLahir,
      tglLahir: this.state.dataPasien.tglLahir,
      alamat: this.state.dataPasien.alamat,
      noTelp: this.state.dataPasien.noTelp,
      asuransi: this.state.dataPasien.asuransi,
      noAsuransi: this.state.dataPasien.noAsuransi,
      noRujukan: this.state.noRujukan,
      poli: this.state.poli,
      tglBerkunjung: this.state.tglBerkunjung,
      jamBerkunjung: this.state.jamBerkunjung,
      noRM: this.state.dataPasien.noRM,
      queueNumber: this.state.dataTable.length + 1
    };
  }

  _handleRegist = (e) => {
    e.preventDefault();
    let id = Math.random().toString(36).substring(2, 12);
    let db = this.state.database;
    let data = this.getAllData();

    set(ref(db, 'pasien/' + data.queueNumber), {
      noRM: data.noRM?.toString(),
      nik: data?.nik,
      namaLengkap: data?.namaLengkap,
      jenisKelamin: data?.jenisKelamin,
      tempatLahir: data?.tempatLahir,
      tglLahir: data?.tglLahir,
      alamat: data?.alamat,
      noTelp: data?.noTelp,
      asuransi: data?.asuransi,
      noAsuransi: data?.noAsuransi,
      noRujukan: data?.noRujukan,
      poli: data?.poli,
      tglBerkunjung: data?.tglBerkunjung,
      jamBerkunjung: data?.jamBerkunjung,
      noRegistrasi: id,
      queueNumber: data?.queueNumber
    });

    this.setState({
      modalSubmit: true, loading: true,
      noRegistrasi: id, noRM: data.noRM?.toString()
    }, () => setTimeout(() => this.setState({ loading: false }), 500));
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      confirmation: !this.state.confirmation,
      modalSubmit: !this.state.modalSubmit
    });
  }

  _handleChangeNoRM = (e, val) => {
    this.setState({ dataPasien: val, showFields: !this.state.showFields });
  }

  _handleChangeValRM = (e) => {
    this.setState({ dataPasien: e.target.value });
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleShowDoc = () => {
    this.setState({ previewDoc: !this.state.previewDoc })
  }

  _handleCloseAll = () => {
    this.setState({
      modalSubmit: false,
      confirmation: false,
      dataPasien: {},
      noRujukan: '',
      poli: '',
      tglBerkunjung: '',
      jamBerkunjung: '',
    }, () => window.location.reload());
  }

  _renderPreviewDoc = () => {
    return (
      <Modal open={this.state.previewDoc}>
        <Box sx={styleModalListPatients}>
          <IconButton
            style={{
              position: 'absolute',
              top: '-31px',
              right: '-34px',
              zIndex: 2,
              color: 'white'
            }}
            onClick={this._handleShowDoc}
          >
            <Cancel fontSize='large' />
          </IconButton>
          <PDFViewer style={viewerListPatients}>
            <Document>
              <Page size="A4" style={page}>
                <View style={section}>
                  <PreviewDoc data={this.state} pasienLama />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    );
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
                <IconButton onClick={this._handleCloseModal}>
                  <Close />
                </IconButton>
              </div>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Pendaftaran Berhasil Disimpan!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleShowDoc}
              >
                Lihat Bukti Pendaftaran
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  _renderConfirmation = () => {
    return (
      <Modal open={this.state.confirmation}>
        <Box sx={styleModalConfirm}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <HelpOutline color='success' sx={{ fontSize: 150 }} />
            <h3>Apakah Anda Sudah Menyimpan Bukti Pendaftaran?</h3>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ marginRight: 5 }}
                onClick={this._handleCloseModal}
              >
                Belum
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleCloseAll}
              >
                Sudah
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }

  render() {
    const { dataPasien, showFields } = this.state;
    let newData = [...this.state.dataTable.reduce((a, c) => (a.set(c.noRM, c)), new Map()).values()];
    return (
      <div>
        <h3>Pendaftaran Pasien Lama</h3>
        <Grid container spacing={2}>
          {this._renderModalSubmit()}
          {this._renderPreviewDoc()}
          {this._renderConfirmation()}
          <Grid item xs={4}>
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeNoRM}
              options={newData}
              getOptionLabel={(option) => option && option.noRM}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="No Rekam Medis"
                  onChange={this._handleChangeValRM}
                />
              }
            />
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="NIK"
                  value={dataPasien?.nik}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Nama Lengkap"
                  value={dataPasien?.namaLengkap}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Jenis Kelamin"
                  value={dataPasien?.jenisKelamin}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Tempat Lahir"
                  value={dataPasien?.tempatLahir}
                />
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Tanggal Lahir"
                  value={moment(dataPasien?.tglLahir).format('DD-MM-YYYY')}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Alamat"
                  multiline
                  rows={4.3}
                  type='area'
                  value={dataPasien?.alamat}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="No Telepon"
                  value={dataPasien?.noTelp}
                />
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Jenis Asuransi"
                  value={dataPasien?.asuransi}
                />
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="No Asuransi"
                  value={dataPasien?.noAsuransi === '' ? '-' : dataPasien?.noAsuransi}
                />
                <TextField
                  error={this.state.noRujukan === ''}
                  fullWidth
                  className='wrappField'
                  label="No Rujukan"
                  name='noRujukan'
                  onChange={this._handleChange}
                />
                <TextField
                  className='wrappField'
                  error={this.state.poli === ''}
                  id="outlined-select-currency"
                  select
                  label="Tujuan Poliklinik"
                  fullWidth
                  name='poli'
                  onChange={this._handleChange}
                >
                  {['Obgyn', 'Bedah', 'Penyakit Dalam', 'Jantung', 'Urologi'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  error={this.state.tglBerkunjung === ''}
                  className='wrappField'
                  label="Tanggal Berkunjung"
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  name='tglBerkunjung'
                  onChange={this._handleChange}
                />
                <TextField
                  error={this.state.jamBerkunjung === ''}
                  className='wrappField'
                  id="outlined-select-currency"
                  select
                  label="Jam Berkunjung"
                  fullWidth
                  name='jamBerkunjung'
                  onChange={this._handleChange}
                >
                  {['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            }
          </Grid>
          {showFields &&
            <Button
              disabled={
                this.state.noRujukan === '' ||
                  this.state.poli === '' ||
                  this.state.tglBerkunjung === '' ||
                  this.state.jamBerkunjung === '' ? true : false
              }
              fullWidth
              variant='contained'
              onClick={this._handleRegist}
            >
              Daftar
            </Button>
          }
        </Grid>
      </div>
    );
  }
}

export default PasienLama;